<template>
    <div class="maintain">
        <van-empty
            class="custom-image"
            description="系统维护中~"
        >
            <img slot="image" src="../../assets/no_data.png" alt="">
        </van-empty>
    </div>
</template>

<script>
    import { Empty } from 'vant'
    export default {
        name:'maintain',
        data() {
            return {
                
            }
        },
        components: {
            [Empty.name]:Empty,
        },
    }
</script>

<style lang="less" scoped>
    .maintain{
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>